.input-container{
    display: inline-block;
    width: 100%;
}

.font-semibold{
    font-weight: 400 !important;
}

.MuiCheckbox-root {
    padding-left: 0px;
}

.MuiFormControlLabel-root{
    border: 1px solid  #9AA3A0;
}

.MuiFormControlLabel-label{
    flex-grow: 1;
    padding: 16px 16px 16px 0px;
}

.error-message{
    color: #d32f2f;
    font-size: 0.75rem;
    margin-left: 10px;
    
    &.rise{
        position: relative;
        bottom: 0.7rem;
    }
}