.GuestSpeakerCard {
  border-color: rgba(53, 71, 64, 1);
  background-color: rgb(239, 237, 231);

  @apply flex border gap-3 w-full relative;
  border-radius: 24px;

  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 6%;

    @apply py-6 pl-4 pr-6;
  }
    
  &__content-container {
    @apply flex flex-col flex-1 overflow-hidden;

    h5 {
      @apply font-inter text-base md:text-xl text-forest-primary;
    }

    .subheader {
      @apply truncate;
    }
  }

  .grey-container {
    @apply flex flex-col items-center justify-center rounded-xl py-4 px-2 mb-2 ml-1;

    background-color: rgba(226, 224, 219, 1);

    @media screen and (max-width: 1024px) {
      &.association-box{
        width: 100%
      }
      
      &.event-box{
        width: 49%
      }
      
      &.length-box{
        width: 48%
      }
    }
    
    @media screen and (min-width: 1024px) {
      @apply px-10 py-4 mx-2;
    }
  }


  .border-emerald {
    border-color: rgba(154, 163, 160, 1)
  }
  .text-emerald{
    color: rgba(103, 117, 112, 1);
  }
}