.ModuleWrapper {
  &-root {
    @apply w-full px-4 md:container md:mx-auto flex flex-col md:flex-row h-full;
  }

  &-questionsWrapper {
    @apply flex-1 md:px-6 py-8 md:py-0 md:overflow-y-auto relative mt-20 md:mt-0;

    &__bordered {
      @apply border-t md:border-t-0 md:border-l border-forest-100;
    }

    &::-webkit-scrollbar {
      display: none !important;
    }

    @media only screen and (max-width: theme('screens.md')) {
      @apply -mx-4 px-4;
    }
  }

  &-leftContainer {
    @apply md:max-w-[auto] max-w-full pr-4;

    .MuiCollapse-wrapper {
      @media only screen and (max-width: theme('screens.md')) {
        @apply w-full #{!important};
      }
    }

    .ModuleWrapper-leftContainerWrapper {
      @apply max-w-full md:max-w-[340px] mx-auto flex flex-col mt-18 gap-10;
    }
  }

  &-questionsContentContainer {
    @apply max-w-[619px] mx-auto flex flex-col mt-18 md:mt-10 mb-32 md:pl-12;
  }
}