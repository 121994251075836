.Checkbox {
  .MuiCheckbox-root {
    @apply p-0;

    &.Mui-checked {
      color: theme('colors.copper.primary');
    }
  }

  .MuiFormControlLabel-root {
    @apply gap-3 px-1;
    
    .MuiFormControlLabel-label {
      @apply text-sm text-forest-primary;
    }
  }
}