.custom-drawer {
    .MuiDialog-container {
      align-items: flex-end;
    }
    
    .MuiPaper-root {
        @apply max-h-full w-full m-0 rounded-t-3xl rounded-b-none bg-cosmicLatte-primary max-w-full;

        
    }

    &.full-height {
        .MuiPaper-root {
            max-height: calc(100% - 24px);
            height: calc(100% - 24px);
        }   
    }
  
    .MuiDialogContent-root {
      padding: 0px;
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }