.RichTextToolbar-wrapper {
  @apply w-full bg-white border-b border-forest-100 flex flex-col z-30 static;
}

.RichTextToolbar-container {
  background-color: white;
  @apply container mx-auto flex flex-row flex-wrap items-center py-1 self-center justify-center gap-2;
}

.RichTextToolbar-command-group {
  @apply flex flex-row items-center relative gap-2;

  &:not(:last-child)::after {
    content: '';
    @apply w-px h-6 bg-forest-100;
  }
}

.RichTextToolbar-command-button {
  width: 40px;
  height: 40px;
  user-select: none;

  @apply flex items-center justify-center rounded-3xl text-base relative;

  &.dropdown::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #354740 transparent transparent transparent;
    position: absolute;
    top: calc(50% - 2px);
    right: 0px;
  }

  &:hover {
    background: rgba(153, 93, 48, 0.08);
    
    @media only screen and (max-width: 639px) {
      background: initial;
    }
  }
}