.PlannerItem {
  @apply flex flex-1 flex-col p-5 sm:p-6 rounded-3xl;

  &-root {
    @apply gap-4 sm:gap-5 border border-forest-primary rounded-3xl;

  }

  &-header {
    @apply flex flex-col items-start justify-center ml-4;
    
    h3 {
      @apply font-recife text-forest-primary text-2xl;
    }
  }

  &-content {
    @apply flex flex-col;

    gap: 24px;
  }

  &-section {
    @apply flex flex-col;

    .head {
      @apply font-recife text-base text-forest-primary;
    }

    .body {
      @apply font-grotesk text-sm text-forest-300;
    }
  }

  &-footer {
    @apply flex flex-row items-center gap-3;

    button {
      margin: 0px!important;
    }

    & > button {
      @apply flex-1;
    }
  }

  &-header__avatar {
    @apply flex flex-row items-center flex-1 gap-4;
    
    .avatar > div,
    .avatar > div > div {
      @apply h-[24px] w-[24px] sm:h-[48px] sm:w-[48px] text-xs sm:text-lg #{!important};
    }
  }

  &-speaker {
    @apply border-forest-100 lg:flex-row lg:gap-12;

    .PlannerItem-header, .PlannerItem-content, .PlannerItem-footer {
      @apply lg:flex-1;
      padding-bottom: 0px !important;
    }

    .PlannerItem-header {
      border: none;
    }
  }

  & .bg-marygold-400{
    background-color: #EDDFBF;
  }
}

.PlannerItemPendingInvite-root {
  background-color: #E2E0DB;
  text-align: center;
}

