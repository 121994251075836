.introjs-tooltip.firstTooltip {
    .introjs-prevbutton {
    display: none;
    }
}

.ClienListContainer {
    margin-top: 40px;
    @media screen and (max-width: 768px) {
        width: 220px;
    }
}
.emptyStateContainer {
    margin-top: 40px;
    background-color: #E2E0DB;
    border-radius: 24px;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
