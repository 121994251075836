.button-variant-primary, 
.button-variant-secondary,
.button-variant-select,
.button-variant-deselect {
    &.button-size-100 {
        padding: 7px 14px;
    }

    &.button-size-150 {
        padding: 12px 24px;
    }

    &.button-size-200 {
        padding: 16px 24px;
    }

    &.button-size-400 {
        padding: 27px 32px;
    }
}

.button-variant-primary {
    background: theme('colors.forest.primary') !important;
    color: theme('colors.cosmicLatte.primary') !important;
    border-radius: 80px;

    &:hover {
        background:theme('colors.forest.800') !important;
    }
    &:active{
        background:theme('colors.forest.700') !important;
     }
    &:focus,
    &:focus-visible,
    &:focus-within{
        outline-color: theme('colors.forest.primary') !important;
        outline-offset: 4px;
    }

    &:disabled {
        background:theme('colors.forest.100') !important;
        color: theme('colors.forest.400') !important;
        cursor: not-allowed;
    }
}

.button-variant-secondary {
    background: transparent !important;
    color: theme('colors.forest.primary') !important;
    border-width: 1px;
    border-color: theme('colors.forest.primary') !important;
    border-radius: 80px;

    &:hover:enabled {
        border-color:theme('colors.forest.primary') !important;
        background:theme('colors.forest.primary') !important;
        color: theme('colors.cosmicLatte.primary') !important;

        svg {
            
            path {
                fill: theme('colors.cosmicLatte.primary') !important;
            }
            circle {
                stroke: theme('colors.cosmicLatte.primary') !important;
            }
        }
    }
    &:active:enabled{
        border-color:theme('colors.forest.700') !important;
        background:theme('colors.forest.700') !important;
     }
    &:focus:enabled,
    &:focus-visible:enabled,
    &:focus-within:enabled{
        outline-color: theme('colors.forest.primary') !important;
        outline-offset: 4px;

        // svg {
        //     fill: theme('colors.cosmicLatte.primary');
        //  }
    }

    &:disabled {
        border-color: theme('colors.forest.400');
        background: transparent;
        color: theme('colors.forest.400');
        cursor: not-allowed;
    }

    &.selected{
        color: #995D30 !important;
        border-width: 2px;
        border-color:#995D30 !important;
    }
}

.button-variant-text {
    color: theme('colors.forest.primary');
    text-decoration-color: theme('colors.forest.primary');
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 4px;
    

    &:hover {
        color: theme('colors.marigold.primary');
        text-decoration-color: theme('colors.marigold.primary');
    }

    &:active{
        color:theme('colors.marigold.secondary');
        text-decoration-color:theme('colors.marigold.secondary');
     }
    &:focus,
    &:focus-visible,
    &:focus-within{
        color: theme('colors.marigold.primary');
        text-decoration-color: theme('colors.marigold.primary');
        outline-color: theme('colors.forest.primary');
        outline-offset: 2px;
        outline-width: 1px;
    }

    &:disabled {
        border:theme('colors.forest.400');
        background:theme('colors.forest.400');
        cursor: not-allowed;
    }
}

.button-variant-select {
    background: white;
    color: theme('colors.forest.primary');
    border: 1px solid theme('colors.forest.primary');
    border-radius: 80px;

    &:hover {
        border: 3px solid theme('colors.copper.primary');
        margin: -2px;
        color: theme('colors.copper.primary');
    }
    &:active{
        border: 3px solid theme('colors.copper.800');
        margin: -2px;
        color: theme('colors.copper.800');
     }
    &:focus,
    &:focus-visible,
    &:focus-within{
        border: 3px solid theme('colors.copper.primary');
        margin: -2px;
        color: theme('colors.copper.primary');
        outline-color: theme('colors.copper.primary');
        outline-offset: 4px;
    }

    &:disabled {
        border: 1px solid theme('colors.forest.400');
        background:white;
        color: theme('colors.forest.400');
        cursor: not-allowed;
    }
}