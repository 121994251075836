.TextField {
  @apply flex flex-col gap-3;

  &__label {
    @apply font-inter text-sm text-forest-300;
  }

  .MuiTextField-root {
    @apply w-fit;

    &.MuiFormControl-fullWidth {
      @apply w-full;
    }
  }

  .MuiInputLabel-root {
    @apply font-inter text-sm;

    &.MuiInputLabel-shrink {
      @apply text-copper-primary;
    }
  }

  .MuiInputBase-root {
    input {
      @apply font-inter text-sm py-[14px] px-4;
    }

    textarea {
      @apply font-inter text-sm;
    }

    &.MuiInputBase-multiline {
      @apply py-[14px] px-4;
    }

    fieldset {
      @apply border-forest-100;
    }

    &:hover {
      fieldset {
        @apply border-copper-primary;
      }
    }

    &.Mui-focused {
      fieldset {
        @apply border-copper-primary;
      }
    }
  }

  .MuiFormHelperText-root {
    @apply font-inter text-sm mx-0;
  }

  &-grotesk {
    @apply gap-2;
    
    .TextField__label {
      @apply font-grotesk text-forest-500 font-medium;
    }

    .MuiInputBase-root input,
    .MuiInputBase-root textarea {
      @apply font-grotesk font-normal text-base;
    }
  }
}