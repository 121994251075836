.info-text{
    left: calc(100%);
    width: max-content;
    max-width: 300px;
    display: none;
}

.info-container:hover .info-text{
    display: block;
    border: 1px solid #9AA3A0;
    background-color: #EFEDE7;
}