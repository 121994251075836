.SideMenuItem-root {
        @apply py-[9px] flex flex-row cursor-pointer;
        border-radius: 8px;
        min-width: 38px;
        transition: all 0.3s ease-in-out;

    &.SideMenuItem--selected {
        @apply bg-cosmicLatte-dark;
    }

    &.expanded {
        min-width: 224px;
        transition: all 0.3s ease-in-out;
    }
}