@font-face {
  font-family: 'NanumMyeongjo';
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  src: url('./assets/fonts/Nanum-ExtraBold/NanumMyeongjoExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Nanum-ExtraBold/NanumMyeongjoExtraBold.woff') format('woff'),
    url('./assets/fonts/Nanum-ExtraBold/NanumMyeongjoExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumMyeongjo';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/Nanum-Bold/NanumMyeongjoBold.woff2') format('woff2'),
    url('./assets/fonts/Nanum-Bold/NanumMyeongjoBold.woff') format('woff'),
    url('./assets/fonts/Nanum-Bold/NanumMyeongjoBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumMyeongjo';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Nanum-Regular/NanumMyeongjo.woff2') format('woff2'),
    url('./assets/fonts/Nanum-Regular/NanumMyeongjo.woff') format('woff'),
    url('./assets/fonts/Nanum-Regular/NanumMyeongjo.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Inter/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RecifeVariableUpright';
  src: url('./assets/fonts/Recife/VariableFont/RecifeCompleteVariableUpright.ttf') format('truetype');
}

@font-face {
  font-family: 'HankenGrotesk';
  src: url('./assets/fonts/Grotesk/HankenGrotesk-VariableFont_wght.ttf') format('truetype');
}


input:-webkit-autofill, input:-internal-autofill-selected {
  background: white !important;
}

.root-content{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  color: #354740;

  @apply bg-cosmicLatte-light;
}
fieldset{
  border-radius: 0 !important;
}

.MuiPaper-root {
  background-color: '#EFEDE7';
}

.MuiFormControlLabel-root{
  margin: 0 !important;
}

.MuiTab-root {
      @apply font-inter text-base ;

      &.MuiButtonBase-root.MuiTab-textColorPrimary {
        color: theme('colors.forest.disabled') !important;
      }

      &.MuiButtonBase-root.Mui-selected {
        color: theme('colors.forest.primary') !important;
      }
    }

.MuiTabs-indicator {
  background-color: theme('colors.red.output') !important;
}

.data-forms{
  width: 100%;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid transparent;
  -webkit-box-shadow: 0 0 0px 500px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

// .data-forms{
//   width:250px;

//   @media (min-width: 640px) {
//     width:450px
//   }

//   @media (min-width: 768px) {
//     width:520px
//   }
// }


// .data-forms-small{
//   width:250px;

//   @media (min-width: 640px) {
//     width:450px
//   }
// }

// this fixes Material UI issue - the vertical scrollbar dissapears when modal or menu is open (when MuiBackdrop is present)
html {
  overflow-y: auto;
  overflow-x: hidden;
}

html:has(.MuiDialog-container) {
  overflow: hidden;
}

body {
  overflow-y: scroll; /* Show vertical scrollbar */
  width: 100vw;
  box-sizing: border-box;
  @apply bg-cosmicLatte-primary;
}


.hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: manual;
}

.ql-editor {
  font-size: 16px;
}

::-webkit-scrollbar {
  display: none !important;
}



.custom-scrollbar {
  scrollbar-width: thin;
  overflow-y:scroll;
}

.custom-scrollbar::-webkit-scrollbar:horizontal {
  height: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #BDCFD3;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}



.remove-elevio {
  #_elev_io > div{
    visibility: hidden !important;
    opacity: 0 !important;
  }
} 

// on mobile
@media (max-width: 768px) {
  .elevioPopup{
    transform: none !important;
  }
}

.introjs-tooltipReferenceLayer {
  .introjs-tooltip {
    &.customTooltip.customTooltipProvenance {
        width: 100% !important;
      max-width: 400px !important;
      border-radius: 24px !important;
      padding: 12px !important;
      color: #354740 !important;
      font-size: 16px !important;
      font-family: 'Recife', serif !important;

      .introjs-arrow {
        display: none !important;
      }

      .introjs-tooltipbuttons {
        border: none;
        a.introjs-button {
          color: #354740 !important;
          padding: 16px 24px !important;
          font-size: 16px !important;
          text-align: center !important;
          border-radius: 32px !important;
          background-color: #fff !important;
          border-color: #354740 !important;
          font-weight: 400 !important;
          font-family: 'Hanken Grotesk', sans-serif !important;
  
          &.introjs-nextbutton {
            color: #FFF !important;
            background-color: #354740 !important;
            border-color: #354740 !important;
            text-shadow: none !important;
          }
          &.introjs-nextbutton:focus,
          &.introjs-nextbutton:active {
              color: #fff !important;
            background-color: #354740 !important;
            border-color: #354740 !important;
            text-shadow: none !important;
          }

          &.introjs-nextbutton:hover{
              color: #fff !important;
            background-color: #202422 !important;
            border-color: #202422 !important;
            text-shadow: none !important;
          }
        }
      }
  
      .introjs-tooltiptext {
        padding: 8px 20px!important;
        font-family: 'Recife', serif!important;
        font-weight: 150!important;
      }
      .introjs-tooltip-title {
          font-family: 'Recife', serif!important;
        font-weight: 420!important;
        font-size: 24px!important;
      }
    }
  }
}

.cutout-text-bg {
  background-image: linear-gradient(45deg, transparent 50%, white 50%);
  background-size: 300% 100%;
  background-position: right bottom;
  transition: background-position .5s ease;
  clip-path: polygon(0% 0%, 100% 0%, 96% 50%, 100% 100%, 0% 100%);
}

.removeElevio{
  #_elev_io{
    display: none !important;
  }
}

.removeElevioOnMobile{
  @media (max-width: 640px) {
    #_elev_io{
      display: none !important;
    }
  }
}