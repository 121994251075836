.CeremonyChecklist-root {
    @apply flex flex-col mb-20;

    .CeremonyChecklist-header {
        @apply flex flex-row justify-between items-center mb-10;
    }

}

.introjs-tooltip.firstTooltip {
    .introjs-prevbutton {
    display: none;
    }
}


.hide-completed{
    .checklist-item-completed{
        display: none !important;
    }
}
