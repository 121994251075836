.BuilderHeader {
    &-root {
      &.fixed-position {
        @apply fixed md:sticky;
      }
      @apply sticky;
      @apply w-[calc(100%)] p-4 top-0;
      @apply flex flex-row justify-between items-center;
      @apply z-[110] flex flex-row items-center justify-between border-b border-forest-100 bg-cosmicLatte-primary;
    }
  }

.v-divider {
  width: 0;
  border-right: 1px solid ;
}