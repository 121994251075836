.BuilderHeaderHeader {
  &-root {
    &.fixed-position {
      @apply fixed md:sticky;
    }
    @apply sticky;
    @apply py-4 md:px-4 top-0;

    @apply z-[110] w-[calc(100vw-32px)] md:w-[calc(100%)] flex flex-row items-center justify-between border-b border-forest-100 bg-cosmicLatte-primary;

  }
}

.ModuleQuestionsHeader {
  &-root {
    &.fixed-position {
      @apply fixed md:sticky;
    }
    @apply sticky;
    @apply py-4 md:px-4 top-0;

    @apply z-[110] w-[calc(100vw-32px)] md:w-[calc(100%)] flex flex-row items-center justify-between border-b border-forest-100 bg-cosmicLatte-primary;
  }
}
