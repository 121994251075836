.translate{
    .CircularProgressbar{
        .CircularProgressbar-text{
            transform: translateY(-10px);
        }
    }
}

.CircularProgressbar{
    .CircularProgressbar-text{
        @apply font-grotesk;
    }
}


.CircularProgressBar__extra-text{
    @apply font-grotesk text-xs relative z-0 top-[-42px] left-0 w-full h-full flex items-center justify-center;
    
}