.CeremonyDetailsCard-root {
    @apply bg-cashmere-600 px-4 py-4 flex flex-col justify-center;
    border-radius: 24px;
    min-width: 240px;
    transition: all 0.3s ease-in-out;
    &.collapsed{
        min-width: 64px;
        width: 64px;
        @apply items-center;
        transition: all 0.3s ease-in-out;
    }
    .CeremonyDetailsCard-header {
        @apply flex flex-row justify-between
    }

    .CeremonyDetailsCard-info {
        @apply mt-4;
        .CeremonyDetailsCard-info-item {
            @apply flex flex-row justify-between;
        }
    }
}