.ModulePageWrapper {
  .MuiDialog-container {
    align-items: flex-end;
  }

  .MuiPaper-root {
    @media only screen and (min-width: theme('screens.md')) {
      max-height: calc(100vh - 24px);
      height: calc(100vh - 24px);
    }

    @apply max-h-full w-full m-0 rounded-none md:rounded-t-3xl md:rounded-b-none bg-cosmicLatte-primary max-w-full;
  }

  .MuiDialogContent-root {
    padding: 0px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}