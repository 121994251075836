.option{
    @apply border border-solid border-black rounded-xl text-center mt-3;

    &:hover, &.selected{
        @apply text-copper-primary border-copper-primary ;
    }

    &:hover{
        @apply cursor-pointer;
    }

    &.selected{
        @apply border-2;
    }

    &.selected + .conditional-rendering{
        @apply block;
    }
}

.conditional-rendering{
    @apply hidden;
}
