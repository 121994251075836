.MuiInputBase-root {
    width: 100%;
}
.MuiList-root {
    background-color: #EFEDE7;
    box-shadow:0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08);
    border-radius:  0;
    border: 1px solid #9AA3A0;

    .MuiMenuItem-root{
        padding: 8px 16px;

        &:hover {
            background-color: #E2E0DB;
        }
    }
    .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
        background-color: #EFEDE7;
    }
    
} 

