.clientsTableScroller{
  max-width: calc(100vw - 320px);
  overflow-x: scroll;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

}

.clientsTableContainer{
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #CCD1CF;
  border-radius: 24px;
  width: fit-content;

  & .clientsTableHeader {
      background-color: #E2E0DB;
      color:#677570;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
  }

  .CeremonyBasicCard{
    @apply flex flex-row items-center;

    gap:24px;
    padding:16px;
    width:1015px;
  
    &.item-button{
      @apply hidden lg:block
    } 
  
    &.item-options{
      @apply hidden
    }

    & .basicCardCell{
      display: flex;
      align-items: center;

      &.clientsCellName{
        width: 300px;

        .initialsAvatar {
          @apply flex justify-center items-center bg-forest-100  mr-2;
          min-width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      &.clientsCellDate{
          width: 90px;
      }
      &.clientsCellLocation{
          width: 170px;
      }
      &.clientsCellPaymentStatus{
          width: 124px;
      }
      &.clientsCellPaidBy{
          width: 70px;
      }
      &.clientsCellOptions {
          text-align: right;
          flex-grow: 1;
          min-width: 113px;
      }
    }
  }
  
  .TitleCard{
    border: none;
    padding: 0 1.5rem;
  }
}
