.PageWrapper-root {
    @apply bg-cosmicLatte-primary flex min-h-screen;
}

.PageWrapper-header {
    @apply w-full flex flex-row justify-between items-center mb-4;
}

.PageWrapper-menu {
    width:0;
    transition: all 0.3s ease-in-out;
    height: 100vh;
    @apply border border-y-0 border-l-0 border-r-forest-100 fixed h-full px-2 py-6 flex flex-col justify-start items-center;
    }

.PageWrapper-menu.menu-expanded{ 
    width: 258px;
}
    
.PageWrapper-menu.menu-closed {
    height: 100vh;
    transition: all 0.3s ease-in-out;
    width: 80px;
}

.PageWrapper-menu-content {
    @apply overflow-y-auto flex-1;
}

.PageWrapper-header-text, .PageWrapper-header-button {
    transition: all 0.3s ease-in-out;
}

.PageWrapper-content {
    @apply min-h-screen;
    margin-left: 0px;
    transition: all 0.3s ease-in-out;
}

.PageWrapper-content.menu-expanded {
    margin-left: 258px;
}


.PageWrapper-content.menu-closed {
    transition: all 0.3s ease-in-out;
    margin-left: 80px;
}
