.typography {
  &-variant {
    &-primary {
    &.typography-type {
        &-display-1000 {
            font-family: 'RecifeVariableUpright';
            font-weight: 310;
            font-size: 80px;
            line-height: 80px;
        }
        &-display-600 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 40px;
            line-height: 48px;
        }
        &-display-400 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 32px;
            line-height: 40px;
        }
        &-display-200 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 28px;
            line-height: 36px;
        }
        &-display-100 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 24px;
            line-height: 32px;
        }
        &-display-50 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 20px;
            line-height: 28px;
        }
        &-body-600 {
            font-family: 'RecifeVariableUpright';
            font-weight: 310;
            font-size: 18px;
            line-height: 26px;
        }
        &-body-400 {
            font-family: 'RecifeVariableUpright';
            font-weight: 310;
            font-size: 16px;
            line-height: 24px;
        }
        &-body-200 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 14px;
            line-height: 18px;
        }
        &-body-100 {
            font-family: 'RecifeVariableUpright';
            font-weight: 420;
            font-size: 12px;
            line-height: 16px;
        }
    }
    }

    &-functional-high {
        font-family: 'HankenGrotesk';
            font-weight: 700;
        &.typography-type {
            &-body-600 {
                font-size: 18px;
                line-height: 26px;
            }
            &-body-400 {
                font-size: 16px;
                line-height: 24px;
            }
            &-body-200 {
                font-size: 14px;
                line-height: 18px;
            }
            &-body-100 {
                font-size: 12px;
                line-height: 16px;
            }
        }
      
    }

    &-functional-low {
        font-family: 'HankenGrotesk';
        &.typography-type {
            &-body-600 {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
            }
            &-body-400 {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
            &-body-200 {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
            }
            &-body-100 {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    
  }

  &-word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    hyphens: auto;
  }

  &-align {
    &-inherit {
      text-align: inherit;
    }

    &-center {
      text-align: center;
    }
  }
}