.custom-text-input {
    width: 100%;
    background-color: transparent;
    
    &-error {
        color: theme('colors.red.primary');
        border:  1px solid theme('colors.red.primary');
        background:theme('colors.rose.100');

        &:hover {
            border:1px solid theme('colors.red.primary') !important;
        }
    
        &:active {
            border: 1px solid theme('colors.red.primary') !important;
        }
    
        &:focus-visible {
            outline: solid;
            outline-width: 1px;
            outline-color: theme('colors.red.primary') !important;
            outline-offset: 0px;
        }
    }

    &.custom-text-input-size-200 {
        height: 40px;
        padding: 8px;
    }

    &.custom-text-input-size-400 {
        height: 56px;
        padding: 16px;
    }
   
   
    border:  1px solid theme('colors.forest.400');

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: theme('colors.forest.400');
    }

    &:hover {
        border:1px solid theme('colors.forest.primary');
    }

    &:active {
        border: 1px solid theme('colors.forest.primary')
    }

    &:focus-visible {
        outline: solid;
        outline-width: 1px;
        outline-color: theme('colors.forest.primary');
        outline-offset: 0px;
    }

    &:disabled {
        background:theme('colors.forest.100');
        cursor: not-allowed;
        pointer-events: none;
    }
}