.introjs-tooltip.firstTooltip {
    .introjs-prevbutton {
    display: none;
    }
}

.PlannerDashboard {
    
    .PlannerDashboard-resources{
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }
}
