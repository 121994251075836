.alert-box-container {
  @apply container flex flex-row p-3 gap-3 text-forest-primary font-inter text-sm border border-forest-100 bg-forest-superlight;
}

.alert-box-buttons-container {
  @apply container flex flex-row gap-2;
}

.alert-box-button {
  @apply gap-1.5 px-3 py-2 font-inter text-sm text-forest-primary border border-forest-primary uppercase;
}