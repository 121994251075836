[contenteditable=true]{
   border: 2px solid transparent;
   font-family: 'HankenGrotesk';
   font-weight: 400;
   font-size: 18px;
   line-height: 26px;
   &:focus {
      border: 2px solid theme('colors.forest.primary')
   }
 }

[contenteditable=true]:empty:before{
    content: attr(placeholder);
    pointer-events: none;
    color: '#CCD1CF' !important;
    display: block; /* For Firefox */
  }

.ContentEditable-RichTextEditor {
  outline: none;
  caret-color: #995d30;
  white-space: pre-line;
  padding: 16px 0px;

  ul { 
    list-style-type: disc; 
    list-style-position: inside; 
 }
 ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
 }
 ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    @apply ml-4;
 }
 ol ol, ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    @apply ml-4;
 }
}