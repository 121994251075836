.ClientResourcesItemContainer {
    width: 263px;
    //height: 264px;
    padding: 24px;
    border-radius: 24px;
    border: 1px solid #354740
}

.ClientResourcesItemImage {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #CCD1CF;
    padding-top: 12px;
    margin-bottom: 24px;

    & img {
        height: 24px;
        width: 24px;
        margin: auto;
    }
}