.title-component{

    &.p-gold{
        color: #B67D00;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
    }
    
    &.p-forest{
        font-family: 'NanumMyeongjo';
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        color: #354740;
    }
    
    &.h1{
        font-size: 40px;
    }
    
    &.h2{
        font-size: 16px;
    }

}