.upsell-modal-cards-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;
    max-height: calc(100vh - 280px);

    @media screen and (min-width: 768px){
        max-height: calc(100vh - 250px);
    }
}