.footer{
    .logo{
        max-width: 30%;
        position: relative;
        img{
            max-height: 195px;
            max-width: 195px;
            transform: rotate(-90deg);
            mix-blend-mode: color-burn;
        }
        h2{
            position: absolute;
            top: 50px;
            left: 50%;
        }
    }
    .center-footer{
        max-width: 40%;
        .hr{
            width: 100%;
            border-top: 1px solid #354740;

        }
    }
    .contact-footer{
        max-width: 40%;
       
    }
}