.VowsDashboardItem-root {
    @apply flex flex-col lg:flex-row justify-between border;
    background: #EFEDE7;
    border-color: theme('colors.forest.primary');
    border-radius: 24px;
    padding: 24px;

    &.VowsDashboardItem-locked {
        @apply text-forest-400;
    }
}

.VowsDashboardItemToast {
    background: #EFEDE7;
    border: 1px solid theme('colors.forest.primary');
    border-radius: 24px;
    padding: 24px;
    max-width: 542px;

    & .VowsDashboardItemToast-header{
        display: flex;
        gap: 24px;
    }
    & .VowsDashboardItemToast-specifications{
        margin-top: 24px;
        display: flex;
        gap: 24px;
    }
    & .VowsDashboardItemToast-buttonsContainer{
        margin-top: 32px;
        display: flex;
        gap: 8px;
    }
}