.progress {
    width: 100%;
    height: 3px;
  }
  .progress-wrap {
    overflow: hidden;
    position: relative;
    
    .progress-bar {
      left: 0;
      position: absolute;
      top: 0;
    }
  }
  
  