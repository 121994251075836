.PlannerDashboard {
  &-root {
    @apply flex flex-col gap-12 pb-36 sm:pb-20;
  }

  &-section {
    position: relative;
    &__header {
      @apply flex flex-col gap-2;

      max-width: 550px;
    }
  }
}

.introjs-tooltip.firstTooltip {
  .introjs-prevbutton {
  display: none;
  }
}
