.Collaborator {
    &-root {
        @apply mb-2 flex flex-row items-center;

        .Collaborator-avatar {
            @apply w-6 h-6 rounded-xl flex justify-center items-center bg-forest-100
        }

        & .text-marygold-400{
            color: rgba(131, 82, 10, 1);
        }
    }
}