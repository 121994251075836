body .react-tagsinput-input{
    width: 100%;
    
}
.react-tagsinput {
    background-color: transparent;
    overflow-y: scroll;
    max-height: max-content;
    width: 100%;
    min-height: 56px;
    align-items: center;
    display: flex;
}
.react-tagsinput.error{
    background-color: #F6E9E2;
    border-color: #994330;
}
.react-tagsinput-tag{
    background-color: transparent;
    border: 1px solid #9AA3A0;
    border-radius: 80px;
    font-family: HankenGrotesk;
    color: #354740;
    padding: 7px 14px;
}
.react-tagsinput-tag.error{
    color: #994330;
    border-color: #994330;
}
.react-tagsinput-input{
    color: #354740;
    font-family: HankenGrotesk;
}
.react-tagsinput-remove{
    font-size: 16px;
}
.react-tagsinput--focused{
    border: 1px solid #9AA3A0;
}