

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-dots .dot{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
}

.carousel .control-dots .selected {
    box-shadow: none;
    background-color: #995D30;
    width: 12px;
    height: 12px;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #995D30;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #995D30;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(90deg);
    -webkit-transform: rotate(135deg);
}