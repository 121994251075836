.blur-overlay {
    position: relative;
    display: inline-block;
  }
  
  .overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px) saturate(180%) contrast(80%) brightness(120%);
    z-index: 1;
  }

  //Mobile
  @media (max-width: 768px) {
    .overlay {
      align-items: start;
      padding-top: 40px;
    }
  }