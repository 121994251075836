.main-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    z-index: 50;
    background-color: rgb(239, 237, 231);
}

.input-container{
    width: 48%;
    margin-right: 1%;

    @media only screen and (max-width: 768px){
        width: 95%;
    }
}


.full-size-4, .input-container.full-size-4{
    @media only screen and (min-width: 1024px){
        width: 23%;
        margin-right: 1%;
    }
}

.full-size-2, .input-container.full-size-2{
    @media only screen and (min-width: 1024px){
        width: 48%;
        margin-right: 1%;
    }
}

.title-container{
    padding-right: 4%;
    padding-left: 0.5%;
    display: flex;
    justify-content: space-between;
}

.delete{
    color: #994330  ;
    border-bottom: 1px dashed #994330 
}

.error-message{
    color: #d32f2f;
    font-size: 0.75rem;
    margin-left: 10px;
    
    &.rise{
        position: relative;
        bottom: 0.7rem;
    }
}

.card-info-message{
    position: relative;
    bottom: 2rem;
}

