.NewAvatar {
  .MuiAvatar-root {
    width: 48px;
    height: 48px;

    @apply text-base lg:text-xl font-extrabold font-nanum;

    @media only screen and (min-width: theme('screens.lg')) {
      width: 64px;
      height: 64px;
    }
  }
}